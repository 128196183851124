import styled from "@emotion/styled"
import { Grid, Typography } from "@mui/material"
import * as api from "api"
import NextLink from "common/NextLink"
import ResponsiveModelImage from "common/ResponsiveModelImage"
import Shared from "theme/shared"

const Styled = {
  Wrapper: styled.div`
    background-color: ${(p) => p.theme.colors.duskBlue};
    ${(p) => p.theme.breakpoints.down("sm")} {
      margin: 10px auto;
    }
  `,
  Image: styled(ResponsiveModelImage)`
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  `,
  OuterGrid: styled(Grid)`
    flex-direction: column;
    ${(p) => p.theme.breakpoints.up("md")} {
      flex-direction: row;
      &:nth-of-type(even) {
        flex-direction: row-reverse;
        .inner-grid {
          justify-content: flex-end;
          @media (min-width: 1320px) {
            padding: 0px 28px 0 70px;
          }
        }
      }
    }
  `,
  InnerGrid: styled(Grid)`
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding: 22px 22px;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      max-width: ${1280 / 2}px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 70px;
      @media (min-width: 1320px) {
        padding: 0px 70px 0 28px;
      }
    }
  `,
  InnerGridWrapper: styled.div`
    ${(p) => p.theme.breakpoints.up("md")} {
      flex: 1;
    }
  `,
  Button: styled(Shared.Button)`
    font-size: 24px;
    padding: 0;
    border-width: 2px;
    height: 56px;
    width: 279px;
    ${(p) => p.theme.breakpoints.down("sm")} {
      border-width: 1px;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }
  `,
  Title: styled(Typography)`
    color: ${(p) => p.theme.colors.white};
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.46;
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 24px;
    }
  `,
  Description: styled(Typography)`
    color: ${(p) => p.theme.colors.white};
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 100;
    line-height: 1.6;
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 16px;
      line-height: 1.38;
    }
  `,
}

type HomeContentFloatingProps = {
  data: api.ContentFloating[]
}

const HomeContentFloating = ({ data }: HomeContentFloatingProps) => {
  return (
    <Styled.Wrapper>
      {data.map((item, i) => (
        <Styled.OuterGrid key={i} container>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              flexBasis: "unset",
              aspectRatio: "16 / 9",
              position: "relative",
              width: "100%",
            }}
          >
            <Styled.Image
              image={item.image}
              imageSizes={["100vw", "50vw"]}
              cover
            />
          </Grid>
          <Styled.InnerGrid item xs={12} md={6} className="inner-grid">
            <Styled.InnerGridWrapper>
              <Styled.Title>{item.name}</Styled.Title>
              <Styled.Description>{item.description}</Styled.Description>
              <NextLink
                href={item.link.url}
                openInNewTab={item.link.openInNewTab}
                passHref
                notEncoding={true}
              >
                <Styled.Button
                  variant="outlined"
                  color="secondary"
                  title={item.link.title}
                >
                  {item.link.title}
                </Styled.Button>
              </NextLink>
            </Styled.InnerGridWrapper>
          </Styled.InnerGrid>
        </Styled.OuterGrid>
      ))}
    </Styled.Wrapper>
  )
}

export default HomeContentFloating
