import styled from "@emotion/styled"
import * as api from "api"
import { Desktop, Mobile } from "common/MediaQueries"
import SeoMetaData, { seoKeys } from "common/SeoMetaData"
import { StaticPropsFactoryProps } from "common/StaticPropsFactory"
import useCurrentBreakpoint from "common/utils/useCurrentBreakpoint"
import ContactUsForm from "components/ContactUs/ContactUsForm"
import HomeContentFloating from "components/Homepage/HomeContentFloating"
import HomeContentHive from "components/Homepage/HomeContentHive"
import HomeModelsCarrouselDesktop from "components/Homepage/HomeModelsCarrouselDesktop"
import HomeModelsCarrouselMobile from "components/Homepage/HomeModelsCarrouselMobile"
import { GetStaticProps } from "next"

const Styled = {
  ContactUs: styled.div`
    margin: 0 20px;

    ${(p) => p.theme.breakpoints.up("md")} {
      border: solid 1.5px ${(p) => p.theme.colors.silver};
      margin: 80px auto 0;
      max-width: 963px;
      padding: 0 120px 20px;
    }
  `,
}

type HomeProps = StaticPropsFactoryProps & {
  homepage: api.HomePageResponse
}

export const getStaticProps: GetStaticProps<HomeProps, any> = async () => {
  const seo = await api.getSeoKey(seoKeys.Homepage).then(api.extractData)
  const homepage = await api.getHomepage().then(api.extractData)
  return { props: { seo, homepage }, revalidate: 60 }
}

export default function Home({ seo, homepage }: HomeProps) {
  const width = useCurrentBreakpoint()
  return (
    <>
      <SeoMetaData data={seo} />
      <Desktop aboveTheFold>
        <HomeModelsCarrouselDesktop data={homepage.vehicles} />
      </Desktop>
      <Mobile aboveTheFold>
        <HomeModelsCarrouselMobile data={homepage.vehicles} />
      </Mobile>
      <HomeContentFloating data={homepage.contentFloating} />
      <Styled.ContactUs>
        <ContactUsForm appointment />
      </Styled.ContactUs>
      <HomeContentHive data={homepage.contentReferences} width={width} />
    </>
  )
}
