import styled from "@emotion/styled"
import { NavigateBefore, NavigateNext } from "@mui/icons-material"
import { Box, Grid, Typography } from "@mui/material"
import * as api from "api"
import RatioElement from "common/ElementRatio"
import ModelName from "common/ModelName"
import NextLink from "common/NextLink"
import ResponsiveModelImage from "common/ResponsiveModelImage"
import slugify from "common/utils/slugify"
import { useState } from "react"
import "swiper/css/bundle"
import { Autoplay, Navigation, Virtual } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import ContactUsButton from "../ContactUs/ContactUsButton"
import VehicleNotices from "../Vehicle/VehicleNotices"
import VehicleOptions from "../Vehicle/VehicleOptions"
import VehiclePrice from "../Vehicle/VehiclePrice"

const Styled = {
  Wrapper: styled.div`
    position: relative;
    overflow: hidden;
    .swiper {
      min-height: 301px;
      width: 100%;
      /* padding-left: 20
      px; */
    }
    .swiper-slide {
      transition: all 0.3s;
      width: 555px;
      inset-inline-start: calc((1280px - 100vw) / 2.5);
      transform: scale(0.4);
      position: relative;
      filter: blur(4px);
      z-index: 1;
    }
    .swiper-slide-active {
      width: 10px;
      transform: scale(1);
      opacity: 1;
      inset-inline-start: 0;
      filter: blur(0);
      z-index: 2;
    }
    .swiper-slide-prev {
      opacity: 0;
      inset-inline-start: 0;
    }
    .swiper-slide-next {
      opacity: 1;
    }
    /* .swiper-slide-next div {
      opacity: 0;
      transition: opacity 0.3s;
    } */
  `,
  OuterGrid: styled(Grid)`
    flex-direction: column;
    text-align: center;
    text-align: left; // flipped by mui
    flex-direction: row-reverse;
    padding-top: 30px;
    margin-bottom: 30px;
    background-image: url("/triangle.svg");
    background-size: cover;
    background-position: bottom;
  `,
  Grid: styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 20px;
    &.no-right-padding {
      padding-inline-start: 0;
    }
    &.canter {
      align-items: center;
    }
  `,
  ModelName: styled(Typography)`
    color: ${(p) => p.theme.colors.marine};
    font-size: 64px;
    line-height: 0.9;
    margin-bottom: 0.2em;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    direction: rtl; /* flipped by mui */
    span {
      font-weight: normal;
      transform: translateY(7%);
    }
    &.background {
      position: absolute;
      opacity: 0.1;
      bottom: 20px;
      font-size: 180px;
      margin: 0;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
      pointer-events: none;
    }
  `,
  ContactUsButton: styled(ContactUsButton)`
    button {
      padding: 10px 75px;
      margin-top: 10px;
    }
  `,
  Announcement: styled(Typography)`
    font-size: 20px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.brownishPurple};
  `,
  ActiveImage: styled.div`
    text-align: right;
  `,
  FixedDiv: styled.div``,
  Next: styled(NavigateNext)`
    width: 35px;
    height: 35px;
    border: solid 2px ${(p) => p.theme.colors.marine};
    border-radius: 50%;
    cursor: pointer;
    color: ${(p) => p.theme.colors.marine};
  `,
  Prev: styled(NavigateBefore)`
    width: 35px;
    height: 35px;
    border: solid 2px ${(p) => p.theme.colors.marine};
    border-radius: 50%;
    margin-inline-start: 20px;
    cursor: pointer;
    color: ${(p) => p.theme.colors.marine};
  `,
}

type HomeModelsCarrouselDesktopProps = { data: api.HomePageVehicle[] }

const HomeModelsCarrouselDesktop = ({
  data,
}: HomeModelsCarrouselDesktopProps) => {
  const [activeModel, setActiveModel] = useState<api.HomePageVehicle>(data[0])

  return (
    <Styled.Wrapper>
      {/* <Styled.BackImageDiv /> */}
      <Styled.OuterGrid container>
        <Styled.Grid item md={5}>
          <Styled.FixedDiv>
            <Styled.Announcement>
              {activeModel.announcement}
            </Styled.Announcement>
          </Styled.FixedDiv>
          <Grid style={{ position: "relative", width: "auto" }}>
            <Styled.ModelName className="background">
              <ModelName name={activeModel.name} />
            </Styled.ModelName>
            <Styled.ModelName>
              <ModelName name={activeModel.name} />
            </Styled.ModelName>
          </Grid>
          <VehiclePrice price={activeModel.priceWithoutVat} vat={false} />
          <VehiclePrice price={activeModel.priceWithVat} vat={true} />
          <VehicleNotices type="HomePageVehicle" data={activeModel} />
          <div>
            <Styled.ContactUsButton modelId={activeModel.id} />
          </div>
        </Styled.Grid>
        <Styled.Grid item md={7} className="no-right-padding">
          <Swiper
            modules={[Navigation, Autoplay, Virtual]}
            id="swiper"
            dir="ltr"
            loop={true}
            slidesPerView={1.5}
            spaceBetween={1}
            navigation={{
              nextEl: ".swiper-button-next-unique",
              prevEl: ".swiper-button-prev-unique",
            }}
            onSlideChange={(swiper) => setActiveModel(data[swiper.realIndex])}
          >
            {data.map((item, i) => (
              <SwiperSlide key={`slide-${i}`}>
                <RatioElement ratio={16 / 12}>
                  <NextLink href={`/${item.id}/${slugify(item.name)}`}>
                    <ResponsiveModelImage
                      className="model-slide"
                      image={item.image}
                      imageSizes="704px"
                      transparent
                    />
                  </NextLink>
                </RatioElement>
              </SwiperSlide>
            ))}
          </Swiper>
        </Styled.Grid>
        <Styled.Grid item md={12} className="canter">
          <Box>
            <Styled.Next className={"swiper-button-next-unique"} />
            <Styled.Prev className={"swiper-button-prev-unique"} />
          </Box>
        </Styled.Grid>
      </Styled.OuterGrid>
      <VehicleOptions data={activeModel.mainOptions} />
    </Styled.Wrapper>
  )
}

export default HomeModelsCarrouselDesktop
