import styled from "@emotion/styled"
import { Grid, Typography } from "@mui/material"
import * as api from "api"
import RatioElement from "common/ElementRatio"
import NextLink from "common/NextLink"
import ResponsiveModelImage from "common/ResponsiveModelImage"
import slugify from "common/utils/slugify"
import ContactUsButton from "components/ContactUs/ContactUsButton"
import VehicleNotices from "components/Vehicle/VehicleNotices"
import VehicleOptions from "components/Vehicle/VehicleOptions"
import VehiclePrice from "components/Vehicle/VehiclePrice"
import nextLinks from "next.links"
import { useState } from "react"
import "swiper/css/bundle"
import { Autoplay, Navigation, Pagination, Virtual } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import Shared from "theme/shared"

const Styled = {
  Sticky: styled.div`
    margin-bottom: 48px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.white};
    padding: 12px 15px;
  `,
  Wrapper: styled.div`
    padding: 0 15px;
    .swiper {
      width: 100%;
      margin-top: -10vw;
      padding-bottom: 20px;
    }
    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide {
      transform: scale(0.5);
      transition: all 0.3s;
      z-index: 1;
      filter: blur(2px);
    }
    .swiper-slide-active {
      width: 10px;
      transform: scale(1);
      z-index: 2;
      filter: blur(0);
    }
    .swiper-pagination-bullets {
      bottom: 15px;
    }
    .swiper-pagination-bullet-active {
      background: ${(p) => p.theme.colors.oceanBlue};
    }
  `,
  Grid: styled(Grid)`
    flex-direction: column;
    text-align: center;
    margin: -15px;
    margin-bottom: 0;
  `,
  ModelName: styled(Typography)`
    font-size: 32px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.oceanBlue};
    text-transform: uppercase;
  `,
  Announcement: styled(Typography)`
    height: 1.2em;
    font-size: 20px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.brownishPurple};
  `,
  Image: styled(ResponsiveModelImage)`
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  `,
}

type HomeModelsCarrouselMobileProps = { data: api.HomePageVehicle[] }

const HomeModelsCarrouselMobile = ({
  data,
}: HomeModelsCarrouselMobileProps) => {
  const [activeModel, setActiveModel] = useState<api.HomePageVehicle>(data[0])
  return (
    <>
      <Styled.Wrapper>
        <Styled.Grid container spacing={10}>
          <Grid item sm={12} md={5} style={{ padding: 15 }}>
            <Styled.Announcement>
              {activeModel.announcement}
            </Styled.Announcement>
            <Shared.Reverse>
              <Styled.ModelName>{activeModel.name}</Styled.ModelName>
            </Shared.Reverse>
            <VehiclePrice price={activeModel.priceWithoutVat} vat={false} />
            <VehiclePrice price={activeModel.priceWithVat} vat={true} />
            <VehicleNotices type="HomePageVehicle" data={activeModel} />
            <VehicleOptions data={activeModel.mainOptions} />
          </Grid>
          <Swiper
            modules={[Navigation, Pagination, Autoplay, Virtual]}
            id="swiper"
            loop={true}
            centeredSlides={true}
            slidesPerView={1.5}
            spaceBetween={-80}
            pagination={{ dynamicBullets: true }}
            onSlideChange={(swiper) => setActiveModel(data[swiper.realIndex])}
          >
            {data.map((item, i) => (
              <SwiperSlide key={`slide-${i}`}>
                <RatioElement ratio={1.7}>
                  <NextLink href={`/${item.id}/${slugify(item.name)}`}>
                    <Styled.Image
                      image={item.image}
                      className="model-slide"
                      imageSizes={"75vw"}
                      transparent
                      priority={i <= 1 || i === data.length - 1}
                    />
                  </NextLink>
                </RatioElement>
              </SwiperSlide>
            ))}
          </Swiper>
        </Styled.Grid>

        <NextLink href={nextLinks.models} passHref>
          <Shared.Button variant="outlined">למסחריות שלנו</Shared.Button>
        </NextLink>
      </Styled.Wrapper>
      <Styled.Sticky className="sticky">
        <ContactUsButton modelId={activeModel.id} />
      </Styled.Sticky>
    </>
  )
}

export default HomeModelsCarrouselMobile
