import {
  Breakpoint,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material"

import styled from "@emotion/styled"
import * as api from "api"
import NextLink from "common/NextLink"
import ResponsiveModelImage from "common/ResponsiveModelImage"

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 70px;
    }
  `,
  ImageWrapper: styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
  `,
  Image: styled(ResponsiveModelImage)`
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  `,
  GridTitle: styled(ImageListItemBar)`
    justify-content: center;
    background: ${(p) => p.theme.colors.oceanBlueTransperent};
  `,
}

type HomeContentHiveProps = { data: api.ContentReferences[]; width: Breakpoint }

const HomeContentHive = ({ data, width }: HomeContentHiveProps) => {
  const columns = width === "xs" || width === "sm" ? 1 : data.length
  const spacing = width === "xs" || width === "sm" ? 10 : 15
  return (
    <Styled.Wrapper>
      <ImageList
        gap={spacing}
        rowHeight={"auto"}
        cols={columns}
        style={{ marginBottom: 0 }}
      >
        {data.map((item, i) => (
          <ImageListItem key={i}>
            <NextLink
              href={item.link.url}
              openInNewTab={item.link.openInNewTab}
            >
              <Styled.ImageWrapper>
                <Styled.Image
                  image={item.image}
                  imageSizes={["100vw", "33vw"]}
                  cover
                />
              </Styled.ImageWrapper>
              <Styled.GridTitle
                title={item.link.title}
                actionIcon={
                  <IconButton>
                    <img src="/Arrow.svg" alt="Menu" width="24" height="24" />
                  </IconButton>
                }
              />
            </NextLink>
          </ImageListItem>
        ))}
      </ImageList>
    </Styled.Wrapper>
  )
}

export default HomeContentHive
